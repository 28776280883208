<template>
  <div>
    <Footer></Footer>
      <div class="content">
        
        <span class="title">
          “大唐杯”全国大学生新一代信息通信技术大赛竞赛组织架构
          <p class="titext">
            <span>“大唐杯”组委会</span>
            &emsp;&emsp;&emsp;&emsp;&emsp;
            <span>发布于2022-07-19</span>
          </p>
        </span>

        <div class="imgbox">
          <!-- <img  style="width:100%;height: 100%;" src="./Framework_img/img_A.jpg" alt="">  -->
        </div>

        <div class="contbox">
          <p class="text"> 1.大赛组委会</p>
          <p class="text_t">“大唐杯”全国大学生新一代信息通信技术大赛组委会设在工业和信息化部人才交流中心，大赛组委会下设秘书处、专家委员会与仲裁委员会，并由主办方、承办方共同组成。</p>
          <p class="text_title"> 2.大赛组委会职责</p>
          <p class="text_t">（1）负责全国范围内的大赛活动统筹组织工作，并对专家委员会和秘书处提交的问题进行协调和裁决。</p>
          <p class="text_t">（2）审议、修改、通过竞赛章程。</p>
          <p class="text_t">（3）协调主办方筹集竞赛组织、评审、奖励所需的经费。</p>
          <p class="text_t">（4）议决其它应由组委会议决的事项。</p>
          <p class="text_title"> 3.大赛秘书处职责</p>
          <p class="text_t">（1）依照组委会的工作意图，及时传达组委会的工作要求。</p>
          <p class="text_t">（2）负责与大赛各相关单位的沟通与协调。</p>
          <p class="text_t">（3）负责大赛各个工作组之间的联络协调，做好服务工作。</p>
          <p class="text_t">（4）负责起草拟订组委会的各种文件。</p>
          <p class="text_t">（5）负责大赛相关例会、专题会等会议安排，整理会议纪要，并对会议议定的事项进行催办落实和信息反馈。</p>
          <p class="text_t">（6）负责制定、执行大赛各类项目预算。</p>
          <p class="text_t">（7）负责提供竞赛平台、及其后续定制开发、运营和维护。</p>
          <p class="text_t">（8）负责拟定全部竞赛环节的设计、并落地实施。</p>
          <p class="text_t">（9）负责保持与组委会专家、大赛评委的有效沟通。</p>
          <p class="text_t">（10）根据竞赛需要及时提出调整建议。</p>
          <p class="text_t">（11）负责制定大赛宣传方案、完成具体的会务组织与实施。</p>
          <p class="text_t">（12）负责起草、撰写竞赛相关的宣传文字与新闻稿件。</p>
          <p class="text_t">（13）负责大赛日常活动网络信息的发布、更新。</p>
          <p class="text_t">（14）完成组委会交办的其他竞赛相关事务。</p>
          <p class="text_title"> 4.大赛专家委员会</p>
          <p class="text_t">大赛设立全国专家委员会，由主办单位统一聘请的相关学科具有高级职称的专家组成。专家委员会经组委会批准成立，负责竞赛命题与方向、拟定竞赛章程与大纲、独立开展竞赛成绩验收评审等工作。</p>
          <p class="text_title"> 5.大赛专家委员会职责</p>
          <p class="text_t">（1）负责拟定竞赛大纲，制定竞赛题目形式与详细题目，保证题目的合理性与公平性。</p>
          <p class="text_t">（2）协助秘书处完成竞赛章程与评审规则，并制定评审实施细则。</p>
          <p class="text_t">（3）负责竞赛成绩验收与评审。</p>
          <p class="text_t">（4）授权全国组委会秘书处在成绩公示期结束前，接受参赛学校和学生、评委、教师对竞赛成绩的质疑与投诉，并对投诉者的姓名、单位予以保密。</p>
          <p class="text_t">（5）成绩公示期结束前，如出现被质疑投诉成绩或题目，专家组应召开会议，依据投诉者需提供相关证据或明确的线索，复核所涉及成绩与题目，进行复核公示或通知，并对复核结果负责。如投诉者仍然对成绩或比赛结果有异议，则由大赛专家委员会提交至大赛仲裁委员会进行仲裁。</p>
          <p class="text_title"> 6.大赛仲裁委员会</p>
          <p class="text_t">（1）大赛仲裁委员会由大赛组委会组织，并经过大赛组委会资格审核，裁仲裁组由3人组成，设组长１名。</p>
          <p class="text_t">（2）大赛仲裁委员会在大赛组委会领导下开展工作，并对大赛组委会负责。</p>
          <p class="text_title"> 7.大赛仲裁委员会职责</p>
          <p class="text_t">（1）熟悉本次大赛相关赛项的竞赛规程和规则。</p>
          <p class="text_t">（2）掌握本赛项的竞赛进展情况。</p>
          <p class="text_t">（3）受理各参赛选手提交的书面申诉与专家组提交的书面申诉。</p>
          <p class="text_t">（4）对受理的申诉进行深入调查与复核，做出客观、公正的集体仲裁。</p>
          <p class="text_title"> 8.大赛承办方与协办方</p>
          <p class="text_t">经大赛组委会审核批准，“大唐杯”全国大学生新一代信息通信技术大赛由中信科移动通信技术股份有限公司、大唐移动通信设备有限公司、北京市教委北京高校电子信息类专业群承办；北方工业大学、北京邮电大学、武汉职业技术学院协办。</p>
          <p class="text_title"> 9.竞赛承办方与协办方职责</p>
          <p class="text_t">（1）负责拟订竞赛场地的建设方案。</p>
          <p class="text_t">（2）负责竞赛场地建设的指导与监督。</p>
          <p class="text_t">（3）负责竞赛场地的使用与维护。</p>
          <p class="text_t">（4）负责赛区整体活动的组织、管理、协调工作。</p>
          <p class="text_t">（5）负责具体工作机构的建立及各组工作职责的指定。</p>
          <p class="text_t">（6）负责审定各执行组的预案、预算。</p>
          <p class="text_t">（7）负责制定应急管理办法，处理和协调竞赛期间突发事件，确保赛区竞赛活动的顺利进行。</p>
          <p class="text_t">（8）负责起草、设计大赛的各类图片、音像视频等宣传稿件、资料等。</p>
          <p class="text_t">（9）负责比赛现场、开闭幕式的主持、宣传和布置工作。</p>
          <p class="text_t">（10）负责大赛各种证件的设计、制作、管理和发放。</p>
          <p class="text_t">（11）负责赛区日常事务的组织、协调、记录。</p>
          <p class="text_t">（12）负责大赛有关文件的整理并归档，交于组委会秘书处备份。</p>
          <p class="text_t">（13）完成大赛组委会秘书处交办的其他工作。</p>
          <p class="text_title"> 10.大赛经费使用规范</p>
          <p class="text_t">大赛经费管理坚持“统一规划、分级管理，多元筹集、定向投入，专款专用、专账核算，厉行节约、注重绩效”的原则。大赛赛事日常公共运转经费由组委会委托秘书处进行统一管理：竞赛经费（包括企事业单位或组织赞助、报名收入和各赛项承办校自筹的资金,下同）纳入大赛年度预算管理方案，并由组委会表决通过。</p>
          <p class="text_t">大赛秘书处作为大赛组委会的执行机构，负责统筹安排赛事经费，汇报组委会审核批准赛事公共运转支出预算，负责赛事经费的监督管理、项目支出绩效考核与结果应用。做到专款专用，包干使用，并根据竞赛实际情况对经费进行合理分配。,下同）纳入大赛年度预算管理方案，并由组委会表决通过。</p>
          <p class="text_t">大赛组委会是各项预算编制的责任主体，大赛秘书处是支出预算执行与结果的责任主体。大赛组委会和承办单位对预算编制、调整以及预算执行与结果负监督责任。预算由收入预算和支出预算组成，坚持目标相关性、政策相符性、经济合理性的编制原则，按照具体业务分类编制支出预算：例如赛场地配置与竞赛设备部署、竞赛平台运维、课程开发与培训，竞赛委员会与技术论坛组织工作、各类宣传耗材与物料运输等会务支出，需要分门别类进行报表整理与备案。,下同）纳入大赛年度预算管理方案，并由组委会表决通过。</p>
          <p class="text_t">每年大赛执行方案和协办院校确定后，大赛秘书处安排部署预算编制工作，明确预算编制要求，各项支出按照预算编制要求，在1个月内完成赛项预算的编制和报批工作；组委会汇总主办方和各赛区与协办院校申报的预算后，提出具体赛项补助资金方案，由大赛组委会审核后审批和拔付。,下同）纳入大赛年度预算管理方案，并由组委会表决通过。</p>
          <p class="text_title"> 11.大赛申诉与仲裁程序 </p>
          <p class="text_t">（1）各参赛队对不符合大赛和赛项规程规定的仪器设备、材料、物件、竞赛使用工具、用品，赛场管理、竞赛成绩，以及工作人员的不规范行为等，可向赛项仲裁委员会提出书面申诉；</p>
          <p class="text_t">（2）申诉主体为省赛与国赛参赛队伍指导教师；</p>
          <p class="text_t">（3）申诉启动时，参赛队以该队指导教师签字同意的书面报告的形式递交赛项仲裁委员会，报告应对申诉事件的现象、发生时间、涉及人员、申诉依据等进行充分、实事求是的叙述。非书面申诉不予受理。</p>
          <p class="text_t">（4）申诉应在对应赛项比赛结束后24小时内提出，超过申诉时效不予受理。</p>
          <p class="text_t">（5）仲裁委员会在收到书面申诉报告后的24小时内组织复议，并及时将复议结果以书面形式告知申诉方，申诉方对复议结果仍有异议，可由学校教师代表向大赛委员会提出申诉。大赛委员会审核后的仲裁结果为最终结果。</p>
          <p class="text_title"> 12.附则 </p>
          <p class="text_t">（1）大赛组委会有义务不泄露参赛项目中涉及技术秘密和商业秘密；有关本次竞赛的所有信息经组委会授权后方可在媒体及网站上公布。</p>
          <p class="text_t">（2）本文件自公布之日起生效，解释权属大赛组委会。</p>
          <div class="img_box">
          </div>
        </div>
      </div>

  <Bottom></Bottom>
  </div>
</template>

<script>
import Footer from '@/components/Footer/Footer.vue'
import Bottom from '@/components/Bottom/Bottom.vue'
import $ from "jquery";
  export default {
    name: 'Home',
    components: {
        Footer,
        Bottom
    },
    data(){
      return {

      }
    },
    methods:{
    },
    mounted(){

    },
    created(){

    },
  }
  </script>
  
  <style scoped lang="scss">

  @import './Framework.scss';
  </style>
  